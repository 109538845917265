<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="优惠券名称" >
                <el-input v-model="search.youHuiQuanMC" size="small" />
            </x-search-item>
            <x-search-item label="优惠券状态" >
                <el-input v-model="search.youHuiQuanZT" size="small" />
            </x-search-item>
            <x-search-item label="会员名称" >
                <el-input v-model="search.huiYuanMC" size="small" />
            </x-search-item>
            <x-search-item label="会员手机号" >
                <el-input v-model="search.huiYuanShouJiHao" size="small" />
            </x-search-item>
            <x-search-item label="使用状态" >
                <el-input v-model="search.shiYongZT" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
<!--            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>-->
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
<!--            <el-table-column width="180" prop="youHuiQuanID" label="优惠券ID" />-->
<!--            <el-table-column width="180" prop="huiYuanID" label="会员ID" />-->
            <el-table-column width="180" prop="huiYuanMC" label="会员名称" />
            <el-table-column width="180" prop="youHuiQuanMC" label="优惠券名称" />
            <el-table-column width="180" prop="youHuiMenJian" label="优惠门槛" />
            <el-table-column width="180" prop="youHuiJinE" label="优惠金额" />
            <el-table-column width="180" prop="youXiaoQiShiSJ" label="有效起始时间" />
            <el-table-column width="180" prop="youXiaoJieShuSJ" label="有效结束时间" />
            <el-table-column width="180" prop="youHuiQuanZT" label="优惠券状态" >
                <x-dict-show slot-scope="{row}" :code="row.youHuiQuanZT" dictType="T_YHQ_ZT"/>
            </el-table-column>
            <el-table-column width="180" prop="huiYuanShouJiHao" label="会员手机号" />
            <el-table-column width="180" prop="faFangSJ" label="发放时间" />
            <el-table-column width="180" prop="shiYongZT" label="使用状态" >
                <x-dict-show slot-scope="{row}" :code="row.shiYongZT" dictType="T_YHQ_SY_ZT"/>
            </el-table-column>
            <el-table-column width="180" prop="shiYongSJ" label="使用时间" />
<!--            <el-table-column width="180" prop="shiYongMenDian" label="使用门店" />-->
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
<!--                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>-->
<!--                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">-->
<!--                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>-->
<!--                    </el-popconfirm>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/YouHuiQuanFaFangJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/yhqffjl/YouHuiQuanFaFangJiLuEdit";
    import Detail from "@/view/hygl/yhqffjl/YouHuiQuanFaFangJiLuDetail";

    export default {
        name: "YouHuiQuanFaFangJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    youHuiQuanMC: '',
                    youHuiQuanZT: '',
                    huiYuanMC: '',
                    huiYuanShouJiHao: '',
                    shiYongZT: '',
                },
            }
        }
    }
</script>

<style scoped>
</style>
