<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="优惠券ID" prop="youHuiQuanID" >
                <el-input v-model="form.youHuiQuanID" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员ID" prop="huiYuanID" >
                <el-input v-model="form.huiYuanID" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="优惠券名称" prop="youHuiQuanMC" >
                <el-input v-model="form.youHuiQuanMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="优惠门槛" prop="youHuiMenJian" >
                <el-input v-model="form.youHuiMenJian" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="优惠金额" prop="youHuiJinE" >
                <el-input v-model="form.youHuiJinE" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="有效起始时间" prop="youXiaoQiShiSJ">
                <el-date-picker v-model="form.youXiaoQiShiSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="有效结束时间" prop="youXiaoJieShuSJ">
                <el-date-picker v-model="form.youXiaoJieShuSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="优惠券状态" prop="youHuiQuanZT" >
                <el-input v-model="form.youHuiQuanZT" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员名称" prop="huiYuanMC" >
                <el-input v-model="form.huiYuanMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员手机号" prop="huiYuanShouJiHao" >
                <el-input v-model="form.huiYuanShouJiHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="发放时间" prop="faFangSJ">
                <el-date-picker v-model="form.faFangSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="使用状态" prop="shiYongZT" >
                <el-input v-model="form.shiYongZT" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="使用时间" prop="shiYongSJ">
                <el-date-picker v-model="form.shiYongSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="使用门店" prop="shiYongMenDian" >
                <el-input v-model="form.shiYongMenDian" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/YouHuiQuanFaFangJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                youHuiQuanID: [], // 优惠券ID
                huiYuanID: [], // 会员ID
                youHuiQuanMC: [], // 优惠券名称
                youHuiMenJian: [], // 优惠门槛
                youHuiJinE: [], // 优惠金额
                youXiaoQiShiSJ: [], // 有效起始时间
                youXiaoJieShuSJ: [], // 有效结束时间
                youHuiQuanZT: [], // 优惠券状态
                huiYuanMC: [], // 会员名称
                huiYuanShouJiHao: [], // 会员手机号
                faFangSJ: [], // 发放时间
                shiYongZT: [], // 使用状态
                shiYongSJ: [], // 使用时间
                shiYongMenDian: [], // 使用门店
            }
            this.titlePrefix = '优惠券发放记录';
            this.defaultForm = {
                id: null,
                youHuiQuanID: "", // 优惠券ID
                huiYuanID: "", // 会员ID
                youHuiQuanMC: "", // 优惠券名称
                youHuiMenJian: "", // 优惠门槛
                youHuiJinE: "", // 优惠金额
                youXiaoQiShiSJ: "", // 有效起始时间
                youXiaoJieShuSJ: "", // 有效结束时间
                youHuiQuanZT: "", // 优惠券状态
                huiYuanMC: "", // 会员名称
                huiYuanShouJiHao: "", // 会员手机号
                faFangSJ: "", // 发放时间
                shiYongZT: "", // 使用状态
                shiYongSJ: "", // 使用时间
                shiYongMenDian: "", // 使用门店
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>