<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
<!--            <x-detail-item prop="youHuiQuanID" label="优惠券ID" />-->
<!--            <x-detail-item prop="huiYuanID" label="会员ID" />-->
            <x-detail-item prop="huiYuanMC" label="会员名称" />
            <x-detail-item prop="youHuiQuanMC" label="优惠券名称" />
            <x-detail-item prop="youHuiMenJian" label="优惠门槛" />
            <x-detail-item prop="youHuiJinE" label="优惠金额" />
            <x-detail-item prop="youXiaoQiShiSJ" label="有效起始时间" />
            <x-detail-item prop="youXiaoJieShuSJ" label="有效结束时间" />
            <x-detail-item prop="youHuiQuanZT" label="优惠券状态" >
                <x-dict-show :code="entity.youHuiQuanZT" dictType="T_YHQ_ZT"/>
            </x-detail-item>

            <x-detail-item prop="huiYuanShouJiHao" label="会员手机号" />
            <x-detail-item prop="faFangSJ" label="发放时间" />
            <x-detail-item prop="shiYongZT" label="使用状态" >
                <x-dict-show :code="entity.shiYongZT" dictType="T_YHQ_SY_ZT"/>
            </x-detail-item>
            <x-detail-item prop="shiYongSJ" label="使用时间" />
            <x-detail-item prop="shiYongMenDian" label="使用门店" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/YouHuiQuanFaFangJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                youHuiQuanID: "", // 优惠券ID
                huiYuanID: "", // 会员ID
                youHuiQuanMC: "", // 优惠券名称
                youHuiMenJian: "", // 优惠门槛
                youHuiJinE: "", // 优惠金额
                youXiaoQiShiSJ: "", // 有效起始时间
                youXiaoJieShuSJ: "", // 有效结束时间
                youHuiQuanZT: "", // 优惠券状态
                huiYuanMC: "", // 会员名称
                huiYuanShouJiHao: "", // 会员手机号
                faFangSJ: "", // 发放时间
                shiYongZT: "", // 使用状态
                shiYongSJ: "", // 使用时间
                shiYongMenDian: "", // 使用门店
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>